import Vue from 'vue';
import App from './App.vue';
import router from './router';
import './plugins/element.js';

// 引入全局样式表
import './assets/css/global.css';
// 导入字体图标
import './assets/fonts/iconfont.css';
// 使用axios
import axios from 'axios';

axios.defaults.baseURL = window.location.origin + '/api';
// axios.defaults.baseURL = 'http://localhost:8888/api';
axios.interceptors.request.use((config) => {
  config.headers.Authorization = window.localStorage.getItem('token');
  return config;
});

axios.interceptors.response.use(
  (response) => {
    const status = response.status;
    if (status === 200) {
      const { code } = response.data;
      if (code === '401') {
        window.sessionStorage.setItem('targetRouter', router.currentRoute.path);
        router.push('/login');
      }

      if (code === '403') {
        window.sessionStorage.setItem('targetRouter', router.currentRoute.path);
        router.push('/login');
      }

      return response;
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

Vue.prototype.$http = axios;
Vue.prototype.$fileUrl = (fileId) => axios.defaults.baseURL.concat('/file/download/', fileId, '?Authorization=', window.localStorage.getItem('token'));

Vue.config.productionTip = false;

document.addEventListener('gesturestart', (e) => {
  e.preventDefault();
});

let startX, startY;

document.addEventListener('touchstart', function (e) {
  startX = e.targetTouches[0].pageX;
  startY = e.targetTouches[0].pageY;
});

document.addEventListener(
  'touchmove',
  function (e) {
    const moveX = e.targetTouches[0].pageX;
    const moveY = e.targetTouches[0].pageY;

    if (Math.abs(moveX - startX) > Math.abs(moveY - startY)) {
      e.preventDefault();
    }
  },
  { passive: false }
);

new Vue({
  render: (h) => h(App),
  router: router
}).$mount('#app');
