<template>
  <el-container class="container">
    <el-header class="c-header">
      <el-page-header @back="goBack" content="图文创款"> </el-page-header>
    </el-header>
    <el-main style="overflow-y: auto; margin-top: 60px; margin-bottom: 60px; height: calc(100% - 120px)">
      <el-row :gutter="1" style="height: 100px">
        <el-col :span="7" style="margin: 5px; background-color: #4a5569; border-radius: 8px; display: flex; align-items: center; justify-content: center; overflow: hidden">
          <el-image :src="thumbnail" :preview-src-list="preview_thumbnail" fit="contain" v-if="thumbnail"></el-image>
        </el-col>
        <el-col :span="6">
          <input type="file" ref="fileInput" style="display: none" @change="handleFileChange" />
          <span style="display: block; position: absolute; margin-top: 20px; font-size: 12px; color: #5b5a5a">参考图</span>
          <el-button type="primary" size="mini" style="position: absolute; transform: translate(0, 50px)" @click="openFileInput" round>{{ btnText }}</el-button>
        </el-col>
        <el-col :span="6" hidden="hidden"> </el-col>
        <el-col :span="5"><div class="grid-content"></div></el-col>
      </el-row>

      <div class="c-body">
        <el-row style="height: 360px; display: flex; align-items: center; justify-content: center; background-color: #4a5569">
          <el-col :span="24">
            <el-image :src="currentPicUrl" :preview-src-list="pic_results" fit="contain" v-if="currentPicUrl"></el-image>

            <!--            <el-carousel trigger="click" height="360px">-->
            <!--              <el-carousel-item v-for="item in pic_results" :key="item">-->
            <!--                <el-image :src="item" fit="contain"></el-image>-->
            <!--              </el-carousel-item>-->
            <!--            </el-carousel>-->
          </el-col>
        </el-row>
      </div>
      <el-row style="display: block; height: 30px; margin-bottom: 5px">
        <el-col :span="24">
          <el-button-group style="position: relative; left: 50%; transform: translate(-50%, 0)">
            <el-button type="primary" icon="el-icon-arrow-left" size="mini" @click="prevPic" :disabled="prevBtnDisabled">上一张</el-button>
            <el-button type="primary" size="mini" @click="nextPic" :disabled="nextBtnDisabled">下一张<i class="el-icon-arrow-right el-icon--right"></i></el-button>
          </el-button-group>
        </el-col>
      </el-row>

      <el-row :gutter="1" class="input-row">
        <el-col :span="24">
          <el-input placeholder="鞋品类，参考品牌名，..." v-model="inputText" :style="{ '--inputColor': '#4a5569' }" style="margin: 0" clearable> </el-input>
        </el-col>
      </el-row>
      <el-row :gutter="1" class="input-row">
        <el-col :span="8">
          <div class="myInput">
            <el-input ref="craft" :style="{ '--inputColor': '#4a5569' }" v-model="craftLabel" @focus="showDraw" readonly placeholder="工艺">
              <i
                v-if="craft"
                slot="suffix"
                class="el-input__icon el-icon-circle-close"
                @click="
                  craft = null;
                  craftLabel = null;
                "
              ></i>
            </el-input>
          </div>
        </el-col>
        <el-col :span="8">
          <el-input ref="baseColor" :style="{ '--inputColor': base_color ? base_color.value : '#4a5569' }" @focus="showBaseColorDraw" readonly placeholder="基础色">
            <i v-if="base_color" slot="suffix" class="el-input__icon el-icon-circle-close" @click="base_color = null"></i>
          </el-input>
        </el-col>
        <el-col :span="8">
          <el-input
            ref="auxiliaryColor"
            :style="{ '--inputColor': auxiliary_color ? auxiliary_color.value : '#4a5569' }"
            @focus="showAuxiliaryColorDraw"
            placeholder="辅助色"
            readonly
          >
            <i v-if="auxiliary_color" slot="suffix" class="el-input__icon el-icon-circle-close" @click="auxiliary_color = null"></i>
          </el-input>
        </el-col>
      </el-row>

      <el-row :gutter="1" class="input-row">
        <el-col :span="8">
          <el-select v-model="element" :style="{ '--inputColor': '#4a5569' }" multiple collapse-tags placeholder="元素">
            <el-option v-for="item in element_options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-col>
        <el-col :span="8">
          <el-select v-model="face_material" :style="{ '--inputColor': '#4a5569' }" multiple collapse-tags placeholder="鞋面材质">
            <el-option v-for="item in face_material_options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-col>
        <el-col :span="8">
          <el-select v-model="bottom_material" :style="{ '--inputColor': '#4a5569' }" multiple collapse-tags placeholder="鞋底材质">
            <el-option v-for="item in bottom_material_options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row style="margin-bottom: 30px">
        <el-col :span="6" style="display: flex; align-items: center">
          <span style="font-size: 14px; color: aliceblue; margin-left: 10px; margin-top: 2px">变化幅度：</span>
        </el-col>
        <el-col :span="16">
          <div class="mySlider">
            <el-slider v-model="rangeability" :min="0" :max="10" :show-tooltip="tooltipStatus" @input="tooltipStatus = true" @change="tooltipStatus = false"></el-slider>
          </div>
        </el-col>
        <el-col :span="2"></el-col>
      </el-row>
      <el-row :gutter="1" style="height: 50px; width: 100%"> </el-row>
      <el-drawer title="请选择工艺" size="70%" :visible.sync="drawer" direction="btt" style="z-index: 20000">
        <div style="height: 35px; width: 40%; margin-left: 20px; display: flex; justify-content: left; float: left" v-for="item in craft_options" :key="item.value">
          <el-radio
            :label="item.label"
            :value="item.value"
            @input="
              craft = item;
              craftLabel = item.label;
              drawer = false;
            "
          >
            {{ item.label }}
          </el-radio>
        </div>
      </el-drawer>

      <el-drawer :title="colorDrawerTitle" size="88%" :visible.sync="colorDrawer" direction="btt">
        <el-container style="width: 100%; height: 100%; border: 1px solid #171631">
          <el-aside style="width: 150px; height: 100%">
            <el-menu default-active="0" @select="colorMenuSelect">
              <el-menu-item
                :index="index.toString()"
                v-for="(item, index) in colors"
                :key="item.value"
                style="border-right: 0.1mm solid #171631; display: flex; align-items: center; background-color: #171631"
              >
                <div :style="{ backgroundColor: item.value, width: '45px', height: '28px', border: '0.1mm solid #171631', borderRadius: '4px', marginRight: '10px' }"></div>
                <span style="font-size: 13px">{{ item.label }}</span>
              </el-menu-item>
            </el-menu>
          </el-aside>
          <el-main style="height: 100%; padding: 0 20px; background-color: #4a5569">
            <div @click="clickColor(item)" v-for="item in subBaseColors" :key="item.value" style="display: flex; align-items: center; height: 56px; margin: 0">
              <div :style="{ backgroundColor: item.value, width: '95px', height: '28px', border: '0.1mm solid #171631', borderRadius: '4px', marginRight: '10px' }"></div>
              <span style="font-size: 13px; color: aliceblue; margin-right: 0">{{ item.label }}</span>
            </div>
          </el-main>
        </el-container>
      </el-drawer>
    </el-main>
    <el-footer class="c-foot">
      <el-button type="primary" style="position: relative; left: 70%; top: 50%; transform: translate(0, -50%)" @click="submit" round>生 成</el-button>
    </el-footer>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      btnText: '上传图片',
      thumbnail: '',
      preview_thumbnail: [],
      pictureId: '',
      loadingProgress: 120,
      loadingInterval: null,
      loading: null,
      colorDrawerTitle: '',
      colorMenuIndex: 0,
      tooltipStatus: true,
      rangeability: 0,
      colors: [
        {
          value: 'rgb(239, 225, 206)',
          label: '白色系',
          children: [
            { value: 'rgb(240, 243, 251)', label: '白色' },
            { value: 'rgb(245, 247, 246)', label: '亮白色' },
            { value: 'rgb(243, 244, 245)', label: '朗讯白色' },
            { value: 'rgb(236, 236, 231)', label: '星形白色' },
            { value: 'rgb(233, 233, 231)', label: '白中白' },
            { value: 'rgb(242, 240, 227)', label: '奶油白' },
            { value: 'rgb(239, 240, 241)', label: '平淡白' },
            { value: 'rgb(239, 225, 206)', label: '珍珠象牙色' },
            { value: 'rgb(219, 202, 191)', label: '杏桃色' },
            { value: 'rgb(174, 179, 177)', label: '幻影灰' },
            { value: 'rgb(150, 153, 156)', label: '终极灰' }
          ]
        },
        {
          value: 'rgb(249, 185, 92)',
          label: '橙黄色系',
          children: [
            { value: 'rgb(248, 243, 84)', label: '鲜黄色' },
            { value: 'rgb(253, 250, 4)', label: '柠檬黄' },
            { value: 'rgb(252, 230, 0)', label: '黄色' },
            { value: 'rgb(239, 211, 83)', label: '原野黄' },
            { value: 'rgb(242, 203, 126)', label: '杏黄色' },
            { value: 'rgb(216, 187, 156)', label: '米黄色' },
            { value: 'rgb(217, 185, 162)', label: '米色' },
            { value: 'rgb(235, 227, 156)', label: '霞多丽白葡萄酒' },
            { value: 'rgb(232, 216, 141)', label: '吉士' },
            { value: 'rgb(253, 219, 39)', label: '活力黄' },
            { value: 'rgb(249, 185, 92)', label: '琥珀黄' },
            { value: 'rgb(230, 180, 155)', label: '近杏色' },
            { value: 'rgb(243, 219, 116)', label: '金翅雀' },
            { value: 'rgb(246, 234, 123)', label: '柠檬马鞭草' },
            { value: 'rgb(246, 230, 62)', label: '亮黄色' },
            { value: 'rgb(249, 226, 68)', label: '金凤花' },
            { value: 'rgb(253, 210, 14)', label: '网络黄' },
            { value: 'rgb(254, 126, 96)', label: '珊瑚橙' },
            { value: 'rgb(248, 128, 29)', label: '橙色' },
            { value: 'rgb(255, 127, 40)', label: '橘柚' },
            { value: 'rgb(232, 117, 68)', label: '沙尘橙' },
            { value: 'rgb(252, 96, 0)', label: '亮橙色' },
            { value: 'rgb(252, 175, 90)', label: '金桔' },
            { value: 'rgb(215, 146, 50)', label: '金橙色' },
            { value: 'rgb(242, 94, 53)', label: '红橙色' },
            { value: 'rgb(255, 123, 37)', label: '黄莺' },
            { value: 'rgb(253, 113, 51)', label: '橘虎' },
            { value: 'rgb(255, 115, 56)', label: '活力橙' },
            { value: 'rgb(247, 149, 93)', label: '风暴黄' },
            { value: 'rgb(228, 122, 46)', label: '赤褐橙' },
            { value: 'rgb(175, 95, 84)', label: '玉米黄' },
            { value: 'rgb(255, 137, 90)', label: '油桃橘' },
            { value: 'rgb(223, 127, 69)', label: '橘褐色' },
            { value: 'rgb(255, 162, 105)', label: '木瓜' },
            { value: 'rgb(241, 134, 67)', label: '阳光橙' },
            { value: 'rgb(253, 130, 62)', label: '橙皮' },
            { value: 'rgb(219, 115, 68)', label: '雅法橙' },
            { value: 'rgb(179, 95, 67)', label: '腐蚀红' },
            { value: 'rgb(162, 85, 58)', label: '肉桂棒' },
            { value: 'rgb(171, 76, 58)', label: '南非国宝茶' }
          ]
        },
        {
          value: 'rgb(112, 89, 77)',
          label: '棕色系',
          children: [
            { value: 'rgb(136, 81, 72)', label: '栗色' },
            { value: 'rgb(95, 36, 29)', label: '棕色' },
            { value: 'rgb(65, 36, 39)', label: '深棕色' },
            { value: 'rgb(44, 20, 20)', label: '咖啡色' },
            { value: 'rgb(113, 70, 64)', label: '樱桃桃花心木' },
            { value: 'rgb(66, 37, 23)', label: '巧克力熔岩蛋糕' },
            { value: 'rgb(77, 67, 62)', label: '巧克力棕' },
            { value: 'rgb(238, 169, 117)', label: '杏花蜜' },
            { value: 'rgb(209, 142, 84)', label: '鹿皮' },
            { value: 'rgb(166, 102, 70)', label: '琥珀棕' },
            { value: 'rgb(139, 90, 61)', label: '焦糖咖啡' },
            { value: 'rgb(228, 136, 46)', label: '深色切达干酪' },
            { value: 'rgb(156, 88, 58)', label: '赭色' },
            { value: 'rgb(115, 97, 91)', label: '深褐色' },
            { value: 'rgb(120, 70, 62)', label: '布兰迪棕' },
            { value: 'rgb(114, 82, 70)', label: '剃过的巧克力' },
            { value: 'rgb(105, 70, 52)', label: '奶油巧克力' },
            { value: 'rgb(112, 89, 77)', label: '可可棕' },
            { value: 'rgb(97, 79, 67)', label: '可可' },
            { value: 'rgb(100, 75, 68)', label: '巧克力实验室' },
            { value: 'rgb(93, 73, 68)', label: '剃过的巧克力' },
            { value: 'rgb(75, 65, 64)', label: '海豹褐' }
          ]
        },
        {
          value: 'rgb(194, 70, 84)',
          label: '红色系',
          children: [
            { value: 'rgb(252, 124, 120)', label: '珊瑚色' },
            { value: 'rgb(246, 102, 103)', label: '珊瑚粉' },
            { value: 'rgb(251, 128, 97)', label: '珊瑚橙' },
            { value: 'rgb(218, 26, 70)', label: '玫瑰红' },
            { value: 'rgb(194, 28, 30)', label: '橘红色' },
            { value: 'rgb(169, 27, 40)', label: '红色' },
            { value: 'rgb(153, 0, 7)', label: '枣红色' },
            { value: 'rgb(85, 5, 8)', label: '褐红色' },
            { value: 'rgb(71, 11, 27)', label: '酒红色' },
            { value: 'rgb(247, 125, 115)', label: '桃红色回声' },
            { value: 'rgb(251, 104, 63)', label: '朱红色' },
            { value: 'rgb(203, 66, 115)', label: '绛紫色' },
            { value: 'rgb(168, 98, 127)', label: '紫红' },
            { value: 'rgb(240, 96, 92)', label: '珊瑚红' },
            { value: 'rgb(188, 61, 58)', label: '熔岩' },
            { value: 'rgb(208, 28, 31)', label: '火红' },
            { value: 'rgb(155, 49, 60)', label: '鲜红色' },
            { value: 'rgb(191, 58, 69)', label: '火星红' },
            { value: 'rgb(210, 57, 62)', label: '火红' },
            { value: 'rgb(204, 51, 61)', label: '高风险红色' },
            { value: 'rgb(189, 72, 72)', label: '欧若拉红' },
            { value: 'rgb(209, 59, 64)', label: '猩红火焰' },
            { value: 'rgb(178, 54, 73)', label: '口红红' },
            { value: 'rgb(176, 43, 59)', label: '针叶樱桃' },
            { value: 'rgb(172, 48, 61)', label: '萨尔萨' },
            { value: 'rgb(149, 21, 40)', label: '鲜红色' },
            { value: 'rgb(154, 43, 46)', label: '熔岩瀑布' },
            { value: 'rgb(142, 31, 44)', label: '有恃无恐' },
            { value: 'rgb(111, 56, 65)', label: '石榴' },
            { value: 'rgb(121, 64, 69)', label: '酒红' },
            { value: 'rgb(117, 61, 90)', label: '洋紫红色' },
            { value: 'rgb(122, 54, 64)', label: '大黄' },
            { value: 'rgb(102, 63, 69)', label: '粉黛' },
            { value: 'rgb(100, 62, 71)', label: '马龙旗' },
            { value: 'rgb(95, 66, 65)', label: '葡萄兰姆酒' }
          ]
        },
        {
          value: 'rgb(248, 129, 146)',
          label: '粉紫色系',
          children: [
            { value: 'rgb(251, 207, 246)', label: '粉红色' },
            { value: 'rgb(242, 171, 172)', label: '鲑鱼粉' },
            { value: 'rgb(145, 111, 171)', label: '薰衣草紫' },
            { value: 'rgb(118, 60, 197)', label: '紫色' },
            { value: 'rgb(75, 17, 117)', label: '极光紫' },
            { value: 'rgb(92, 20, 129)', label: '浆果紫' },
            { value: 'rgb(236, 209, 214)', label: '小猪' },
            { value: 'rgb(246, 228, 217)', label: '奶油粉' },
            { value: 'rgb(247, 206, 224)', label: '樱花' },
            { value: 'rgb(240, 192, 216)', label: '鼻盖' },
            { value: 'rgb(237, 190, 220)', label: '粉钻色' },
            { value: 'rgb(242, 193, 209)', label: '童话' },
            { value: 'rgb(216, 161, 196)', label: '翻糖粉' },
            { value: 'rgb(235, 156, 188)', label: '海棠粉色' },
            { value: 'rgb(210, 143, 176)', label: '牡丹粉' },
            { value: 'rgb(245, 206, 200)', label: '含苞玫瑰' },
            { value: 'rgb(251, 178, 169)', label: '杏子色' },
            { value: 'rgb(227, 201, 206)', label: '苍白紫丁香' },
            { value: 'rgb(230, 205, 197)', label: '蜜桃色' },
            { value: 'rgb(218, 190, 186)', label: '红褐玫瑰' },
            { value: 'rgb(253, 195, 198)', label: '棉花糖粉' },
            { value: 'rgb(248, 191, 168)', label: '柔和香桃色' },
            { value: 'rgb(229, 179, 155)', label: '桃粉沙色' },
            { value: 'rgb(184, 137, 149)', label: '彩妆粉' },
            { value: 'rgb(167, 108, 151)', label: '桑葚' },
            { value: 'rgb(200, 80, 139)', label: '树莓玫瑰' },
            { value: 'rgb(229, 89, 130)', label: '仙客来粉' },
            { value: 'rgb(234, 102, 118)', label: '粉红潘趣酒' },
            { value: 'rgb(211, 46, 94)', label: '娇艳树莓色' },
            { value: 'rgb(210, 56, 108)', label: '闪粉色' },
            { value: 'rgb(158, 44, 106)', label: '节日紫红色' },
            { value: 'rgb(163, 87, 118)', label: '藕雾紫' },
            { value: 'rgb(128, 48, 76)', label: '浆果梅子色' },
            { value: 'rgb(111, 69, 110)', label: '灰调葡萄紫' },
            { value: 'rgb(84, 44, 93)', label: '日式紫' },
            { value: 'rgb(71, 52, 66)', label: '午夜梅子色' },
            { value: 'rgb(236, 158, 172)', label: '牡丹色' },
            { value: 'rgb(244, 152, 160)', label: '火烈鸟粉' },
            { value: 'rgb(221, 114, 125)', label: '茶玫瑰' },
            { value: 'rgb(179, 129, 154)', label: '淡紫兰' },
            { value: 'rgb(153, 104, 125)', label: '醇厚淡紫' }
          ]
        },
        {
          value: 'rgb(204, 215, 127)',
          label: '绿色系',
          children: [
            { value: 'rgb(214, 255, 9)', label: '荧光绿' },
            { value: 'rgb(128, 210, 88)', label: '草绿色' },
            { value: 'rgb(134, 235, 43)', label: '春绿色' },
            { value: 'rgb(34, 151, 112)', label: '绿色' },
            { value: 'rgb(8, 103, 73)', label: '草地绿' },
            { value: 'rgb(2, 105, 65)', label: '松绿色' },
            { value: 'rgb(107, 122, 98)', label: '橄榄色' },
            { value: 'rgb(171, 215, 158)', label: '开心果绿' },
            { value: 'rgb(0, 147, 83)', label: '青蕨色' },
            { value: 'rgb(202, 214, 106)', label: '野酸橙' },
            { value: 'rgb(189, 218, 202)', label: '雾玉' },
            { value: 'rgb(103, 188, 179)', label: '澄澈青蓝' },
            { value: 'rgb(126, 211, 127)', label: '新叶绿' },
            { value: 'rgb(151, 188, 98)', label: '亮柠檬绿' },
            { value: 'rgb(0, 140, 78)', label: '绿色蜜蜂' },
            { value: 'rgb(58, 122, 96)', label: '亚马逊' },
            { value: 'rgb(0, 82, 101)', label: '海青色' },
            { value: 'rgb(31, 73, 91)', label: '青蓝潮汐' },
            { value: 'rgb(21, 70, 62)', label: '深翡翠色' },
            { value: 'rgb(29, 63, 67)', label: '深苔藓色' },
            { value: 'rgb(69, 116, 98)', label: '叶绿' },
            { value: 'rgb(35, 110, 72)', label: '丰富的绿色' },
            { value: 'rgb(34, 103, 78)', label: '绿色夹克' }
          ]
        },
        {
          value: 'rgb(141, 188, 212)',
          label: '蓝色系',
          children: [
            { value: 'rgb(111, 170, 211)', label: '阿拉斯加蓝' },
            { value: 'rgb(2, 222, 254)', label: '霓虹蓝' },
            { value: 'rgb(21, 205, 244)', label: '天蓝色' },
            { value: 'rgb(45, 171, 217)', label: '海蓝色' },
            { value: 'rgb(52, 144, 181)', label: '雪青色' },
            { value: 'rgb(13, 130, 194)', label: '海洋蓝' },
            { value: 'rgb(40, 73, 127)', label: '靛蓝色' },
            { value: 'rgb(5, 68, 130)', label: '高山蓝' },
            { value: 'rgb(33, 56, 115)', label: '钢蓝色' },
            { value: 'rgb(5, 38, 90)', label: '孔雀蓝' },
            { value: 'rgb(193, 214, 234)', label: '冰水' },
            { value: 'rgb(204, 237, 238)', label: '盐雾' },
            { value: 'rgb(156, 200, 198)', label: '鸡蛋壳蓝' },
            { value: 'rgb(157, 184, 184)', label: '蓝雾' },
            { value: 'rgb(160, 192, 221)', label: '晴空蓝' },
            { value: 'rgb(65, 169, 212)', label: '泳帽蓝' },
            { value: 'rgb(55, 161, 194)', label: '地平线蓝' },
            { value: 'rgb(0, 174, 197)', label: '智能蓝' },
            { value: 'rgb(46, 123, 168)', label: '地中海蓝' },
            { value: 'rgb(0, 123, 187)', label: '蓝色紫菀' },
            { value: 'rgb(48, 120, 180)', label: '科技蓝' },
            { value: 'rgb(25, 93, 153)', label: '帝王蓝' },
            { value: 'rgb(69, 104, 154)', label: '星彩蓝宝石' },
            { value: 'rgb(59, 100, 167)', label: '强光蓝' },
            { value: 'rgb(19, 79, 151)', label: '钴蓝' },
            { value: 'rgb(15, 76, 129)', label: '经典蓝色' },
            { value: 'rgb(62, 85, 171)', label: '电光蓝' },
            { value: 'rgb(57, 72, 104)', label: '海军蓝' },
            { value: 'rgb(0, 116, 147)', label: '峡湾蓝' },
            { value: 'rgb(4, 106, 170)', label: '导演蓝' },
            { value: 'rgb(4, 94, 136)', label: '米科诺斯蓝' },
            { value: 'rgb(50, 99, 164)', label: '维多利亚蓝' },
            { value: 'rgb(64, 90, 122)', label: '深蓝色' }
          ]
        },
        {
          value: 'rgb(63, 60, 65)',
          label: '灰黑色系',
          children: [
            { value: 'rgb(187, 195, 205)', label: '灰蓝色' },
            { value: 'rgb(92, 99, 113)', label: '石板灰' },
            { value: 'rgb(0, 0, 0)', label: '雅致黑' },
            { value: 'rgb(197, 201, 202)', label: '冰川灰' },
            { value: 'rgb(228, 227, 221)', label: '百合灰' },
            { value: 'rgb(207, 206, 216)', label: '丹布里岩' },
            { value: 'rgb(188, 188, 190)', label: '安静灰' },
            { value: 'rgb(150, 153, 156)', label: '极致灰' },
            { value: 'rgb(174, 179, 177)', label: '幻影灰' },
            { value: 'rgb(176, 184, 180)', label: '风暴灰' },
            { value: 'rgb(164, 162, 159)', label: '无限灰蓝' },
            { value: 'rgb(144, 140, 146)', label: '海鸥灰' },
            { value: 'rgb(126, 125, 136)', label: '石墨灰' },
            { value: 'rgb(137, 133, 134)', label: '灰色法兰绒' },
            { value: 'rgb(142, 147, 155)', label: '阴天灰' },
            { value: 'rgb(132, 132, 135)', label: '高级灰' },
            { value: 'rgb(102, 104, 108)', label: '罂粟籽灰' },
            { value: 'rgb(97, 94, 95)', label: '花岗岩灰色' },
            { value: 'rgb(89, 95, 102)', label: '湍流' },
            { value: 'rgb(78, 90, 97)', label: '暗石板' },
            { value: 'rgb(82, 82, 87)', label: '沥青' },
            { value: 'rgb(64, 65, 69)', label: '幻影黑' },
            { value: 'rgb(61, 64, 64)', label: '海盗黑' },
            { value: 'rgb(61, 62, 65)', label: '无月之夜' },
            { value: 'rgb(64, 61, 63)', label: '陨石黑' },
            { value: 'rgb(61, 63, 67)', label: '高贵黑' },
            { value: 'rgb(59, 61, 63)', label: '鱼子酱' },
            { value: 'rgb(63, 53, 53)', label: '黑森林' },
            { value: 'rgb(53, 54, 53)', label: '太空黑' },
            { value: 'rgb(166, 160, 166)', label: '鸥灰色' },
            { value: 'rgb(108, 106, 111)', label: '灰色萤石' },
            { value: 'rgb(109, 112, 115)', label: '钢灰色' },
            { value: 'rgb(69, 69, 70)', label: '鸦' },
            { value: 'rgb(60, 63, 72)', label: '碳' },
            { value: 'rgb(37, 38, 41)', label: '燕尾服' },
            { value: 'rgb(39, 39, 43)', label: '断电' }
          ]
        },
        {
          value: 'rgb(173, 165, 162)',
          label: '金属色系',
          children: [
            { value: 'rgb(188, 178, 162)', label: '镀银' },
            { value: 'rgb(222, 176, 169)', label: '玫瑰金' },
            { value: 'rgb(153, 154, 160)', label: '银灰色' },
            { value: 'rgb(178, 180, 185)', label: '银色' },
            { value: 'rgb(158, 158, 163)', label: '银苏格兰' },
            { value: 'rgb(147, 144, 153)', label: '银影' },
            { value: 'rgb(140, 143, 144)', label: '银色' },
            { value: 'rgb(231, 178, 165)', label: '玫瑰金' },
            { value: 'rgb(227, 161, 48)', label: '金边' },
            { value: 'rgb(160, 130, 77)', label: '青铜薄雾' },
            { value: 'rgb(158, 73, 55)', label: '烫金' }
          ]
        }
      ],
      drawer: false,
      colorDrawer: false,
      craft_options: [
        { value: 'Embossing', label: '压纹' },
        { value: 'Weaving', label: '编织' },
        { value: 'Embroidery', label: '刺绣' },
        { value: 'Color rubbing off/Color alteration', label: '擦色/改色' },
        { value: 'Printing', label: '印花' },
        { value: 'Stitched shoes', label: '拉线鞋' },
        { value: 'Injection molded shoes', label: '注塑(射)鞋' },
        { value: 'Electroplating', label: '电镀' }
      ],
      face_material_options: [
        { value: 'Regular Leather', label: '常规皮革' },
        { value: 'Oiled Wax Leather', label: '油蜡皮革' },
        { value: 'Matte Leather', label: '雾面皮革' },
        { value: 'Litchi Grain Leather', label: '荔枝纹皮革' },
        { value: 'Patent Leather', label: '漆皮' },
        { value: 'Suede Leather', label: '反绒皮革' },
        { label: '皮草', value: 'Leather fur' },
        { label: '帆布', value: 'Canvas' },
        { label: '牛仔布', value: 'Denim' },
        { label: '网面', value: 'Mesh' },
        { label: '飞织', value: 'Flyknit' },
        { label: '针织', value: 'Knitted' },
        { label: '丝绒', value: 'Velvet' },
        { label: '半透明材质', value: 'Semi-transparent material' }
      ],
      bottom_material_options: [
        { value: 'Rubber/foam', label: '橡胶/发泡' },
        { value: 'Translucent material (PVC/PU/EVA)', label: '半透明材质(PVC/PU/EVA)' },
        { value: 'Popcorn', label: '爆米花' },
        { value: 'Platform-soled', label: '厚底' },
        { value: 'Waterproof platform', label: '防水台' },
        { value: 'Air cushion sole', label: '气垫底' }
      ],
      inputText: null,
      face_material: [],
      bottom_material: [],
      craft: null,
      element: [],
      element_options: [
        { value: 'leopard print', label: '豹纹' },
        { value: 'zebra print', label: '斑马纹' },
        { value: 'snake skin', label: '蛇纹' },
        { value: 'flower', label: '花朵' },
        { value: 'star', label: '星星' },
        { value: 'bow tie', label: '蝴蝶结' },
        { value: 'jewelry ornament', label: '钻饰' },
        { value: 'buckle', label: '饰扣' },
        { value: 'pearl', label: '珍珠' },
        { value: 'tassel', label: '流苏' },
        { value: 'rivet', label: '铆钉' },
        { value: 'chain', label: '链条' },
        { value: 'decorative flower', label: '装饰花' },
        { value: 'thick decorative thread', label: '粗饰线' },
        { value: 'embroidery', label: '刺绣' },
        { value: 'pattern', label: '图案' },
        { value: 'cartoon', label: '卡通' },
        { value: 'sequin', label: '亮片' },
        { value: 'stitch', label: '绗缝' },
        { value: 'wood grain', label: '木纹' }
      ],
      craftLabel: null,
      base_color: null,
      auxiliary_color: null,
      currentTaskId: null,
      intervalId: null,
      intervalCnt: 60,
      pic_results: [],
      current_pic_index: 0
    };
  },
  methods: {
    goBack() {
      this.$router.push('/function');
    },
    openFileInput() {
      this.$refs.fileInput.click();
    },
    checkFileSize(file) {
      console.log('文件：', file);
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.warning('图片大小超限，文件不能超过 5M');
        return false;
      }
      return true;
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (!file) {
        return;
      }

      if (!file.type.includes('image')) {
        this.$message.warning('只能上传图片！');
        return;
      }

      if (!this.checkFileSize(file)) {
        return;
      }
      const formData = new FormData();
      formData.append('file', file);

      this.$http
        .post('file/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
          if (response.data.code !== '0') {
            this.$message.error('上传失败！');
            return;
          }

          console.log('上传成功', response.data);
          this.pictureId = response.data.data;
          const reader = new FileReader();
          reader.onload = (event) => {
            this.thumbnail = event.target.result;
          };
          reader.readAsDataURL(file);

          this.preview_thumbnail = [];
          this.preview_thumbnail.push(this.convertFileIdToUrl(this.pictureId));

          // 定时任务关闭
          if (this.intervalId) {
            clearInterval(this.intervalId);
          }
          // 清除pic_results
          this.pic_results = [];
          this.current_pic_index = 0;

          this.btnText = '重新上传';
        })
        .catch((error) => {
          console.log(error.toString());
          this.$message.error('服务异常，请稍后再试！');
        });
    },
    clickColor(item) {
      console.log('选择了颜色：' + item);
      this.colorDrawer = false;
      if (this.colorDrawerTitle === '请选择基础色') {
        this.base_color = item;
      }

      if (this.colorDrawerTitle === '请选择辅助色') {
        this.auxiliary_color = item;
      }
    },

    colorMenuSelect(index) {
      this.colorMenuIndex = index;
    },
    showDraw() {
      this.$refs.craft.blur();
      this.drawer = true;
    },
    showBaseColorDraw() {
      this.$refs.baseColor.blur();
      this.colorDrawerTitle = '请选择基础色';
      this.colorDrawer = true;
    },
    showAuxiliaryColorDraw() {
      this.$refs.auxiliaryColor.blur();
      this.colorDrawerTitle = '请选择辅助色';
      this.colorDrawer = true;
    },
    submit() {
      // 定时任务关闭
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }

      // 清除pic_results
      this.pic_results = [];
      this.current_pic_index = 0;

      const params = {};

      params.fileId = this.pictureId;
      params.inputText = this.inputText || ' ';
      params.craft = this.craft?.value || ' ';
      params.base_color = this.base_color?.label || ' ';
      params.auxiliary_color = this.auxiliary_color?.label || ' ';

      params.element = this.element;
      params.face_material = this.face_material;
      params.bottom_material = this.bottom_material;
      params.rangeability = this.rangeability;

      // console.log('params: ' + JSON.stringify(params));

      this.$http
        .post('tc-params/submit', params, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then((response) => {
          const { code, data, message } = response.data;
          if (code !== '0') {
            this.$message.error(message);
            return;
          }
          this.currentTaskId = data;
          this.$message.success('提交成功！');

          this.loadingProgress = 120;
          this.loading = this.$loading({
            lock: true,
            text: '图片生成中... ' + this.loadingProgress + '秒',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });

          this.loadingInterval = setInterval(() => {
            this.loadingProgress -= 1;
            this.loading.setText('图片生成中... ' + this.loadingProgress + '秒');
            if (this.loadingProgress === 0) {
              this.closeLoading();
            }
          }, 1000);

          // 启动定时拉取
          this.startFetchResult();
        })
        .catch((error) => {
          console.log(error.toString());
          this.closeLoading();
          this.$message.error('服务异常，请稍后再试！');
        });
    },
    closeLoading() {
      this.loading.close();
      if (this.loadingInterval) {
        clearInterval(this.loadingInterval);
      }
    },
    convertFileIdToUrl(fileId) {
      return this.$http.defaults.baseURL + '/file/download/' + fileId;
    },

    startFetchResult() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }

      this.intervalCnt = 60;
      this.intervalId = setInterval(() => {
        const params = { taskId: this.currentTaskId };
        this.$http
          .get('fetch-result', { params })
          .then((response) => {
            this.intervalCnt -= 1;
            if (response.data.code !== '0') {
              console.log('定时拉取结果失败：' + response.data.message);
              return;
            }

            console.log('定时拉取结果成功', JSON.stringify(response.data));

            if (this.pic_results.length >= 4 || this.intervalCnt === 0) {
              clearInterval(this.intervalId);
            }

            if (response.data.data.fileIds.length > 0) {
              this.closeLoading();
              this.pic_results = response.data.data.fileIds.map((fileId) => {
                return this.convertFileIdToUrl(fileId);
              });
            }
          })
          .catch((error) => {
            console.log(error.toString());
            this.$message.error('服务异常，请稍后再试！');
          });
      }, 2000);
    },
    prevPic() {
      // 切换到前一张图片
      if (this.current_pic_index > 0) {
        this.current_pic_index--;
      }
    },
    nextPic() {
      // 切换到下一张图片
      if (this.current_pic_index < this.pic_results.length - 1) {
        this.current_pic_index++;
      }
    }
  },
  computed: {
    subBaseColors() {
      return this.colors[this.colorMenuIndex].children;
    },
    currentPicUrl() {
      if (this.pic_results.length > 0) {
        return this.pic_results[this.current_pic_index];
      }
      return null;
      // return 'http://cybershoes.art/api/file/download/955844E0FDD17DAF6ECC29180D99024B.jpg';
    },
    prevBtnDisabled() {
      if (this.pic_results.length < 2) {
        return true;
      }
      return this.current_pic_index === 0;
    },
    nextBtnDisabled() {
      if (this.pic_results.length < 2) {
        return true;
      }
      return this.current_pic_index === this.pic_results.length - 1;
    }
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }

    if (this.loadingInterval) {
      clearInterval(this.loadingInterval);
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  padding: 0;
  background-color: #171631;

  overflow: hidden;
  height: 100vh;
  .el-main {
    padding: 3px;
  }
}

.container {
  :deep(.el-drawer) {
    background-color: #171631;
  }
  :deep(.el-radio) {
    color: #b5bbc1;
  }
  :deep(.el-drawer__header) {
    margin-bottom: 20px;
  }
  :deep(.el-menu-item) {
    color: aliceblue;
  }
  :deep(.el-menu-item.is-active) {
    background-color: #4a5569;
  }
}

.c-header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 50px;
  padding-left: 20px;
  z-index: 2000;
  border-bottom: 0.1mm solid #22374d;
  background-color: #171631;
  color: aliceblue;
}

.c-header {
  :deep(.el-page-header__content) {
    color: aliceblue;
  }
  :deep(.el-page-header__title) {
    font-size: 16px;
    font-weight: 500;
  }
}

.c-body {
  background-color: #4a5569;
  overflow: hidden;
  border-radius: 20px;
  padding: 0;
  margin-top: 5px;
  margin-bottom: 5px;
}

.c-foot {
  height: 50px;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 2000;
  border-top: 0.1mm solid #22374d;
  background-color: #171631;
}

.el-row {
  display: flex;
  width: 100%;
  margin: 0;
}

.btn-center {
  position: relative;
  width: 80%;
  left: 50%;
  transform: translate(-50%, 25%);
}

.doodleBoard {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: rgba(0, 0, 0, 0.015);
  border-radius: 8px;
}
.doodleBoard img {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  padding: 0;
  object-fit: contain;
  border-radius: 8px;
  user-select: none;
}

.doodleBoard canvas {
  position: absolute;
  top: 0;
}

.addDia {
  :deep(.el-dialog__body) {
    padding: 1px;
    height: 60%;
  }
  :deep(.el-dialog__footer) {
    margin-top: 30px;
  }
}

.input-row {
  :deep(.el-input__inner) {
    background-color: var(--inputColor);
    border: 1px solid #171631;
    color: aliceblue;
  }
  :deep(.el-tag.el-tag--info) {
    background-color: #3a4d70;
    border-color: #4a5569;
    color: #909399;
  }
  :deep(.el-select .el-tag) {
    margin-left: 3px;
    padding-left: 2px;
  }
  :deep(.el-tag) {
    font-size: 13px;
  }
  :deep(.el-tag--small) {
    height: 16px;
    //padding: 0 0 2px 0;
    line-height: 15px;
  }
  :deep(.el-select__tags-text) {
    color: aliceblue;
  }
}

.mySlider {
  :deep(.el-slider__bar) {
    height: 2px;
  }

  :deep(.el-slider__runway) {
    height: 2px;
  }

  :deep(.el-slider__button) {
    height: 10px;
    width: 10px;
  }

  :deep(.el-slider__button-wrapper) {
    top: -17px;
  }

  :deep(.el-slider__input) {
    width: 100px;
  }
}
</style>
