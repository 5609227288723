<template>
  <el-container class="container">
    <el-header class="c-header">
      <el-page-header @back="goBack" content="款式融合"> </el-page-header>
    </el-header>

    <el-main style="max-height: fit-content; margin-top: 60px">
      <el-row :gutter="1" style="height: 100px">
        <el-col :span="7" style="margin: 5px; background-color: #4a5569; border-radius: 8px; display: flex; align-items: center; justify-content: center; overflow: hidden">
          <el-image :src="thumbnail" :preview-src-list="preview_thumbnail" fit="contain" v-if="thumbnail"></el-image>
        </el-col>
        <el-col :span="5">
          <input type="file" ref="fileInput" style="display: none" @change="handleFileChange(1, $event)" />
          <span style="display: block; position: absolute; margin-top: 20px; font-size: 12px; color: #5b5a5a">改款鞋图</span>
          <el-button type="primary" size="mini" style="position: absolute; transform: translate(0, 50px)" @click="openFileInput(1)" round>{{ btnText }}</el-button>
        </el-col>

        <el-col :span="7" style="margin: 5px; background-color: #4a5569; border-radius: 8px; display: flex; align-items: center; justify-content: center; overflow: hidden">
          <el-image :src="thumbnail2" :preview-src-list="preview_thumbnail2" fit="contain" v-if="thumbnail2"></el-image>
        </el-col>
        <el-col :span="5">
          <input type="file" ref="fileInput2" style="display: none" @change="handleFileChange(2, $event)" />
          <span style="display: block; position: absolute; margin-top: 20px; font-size: 12px; color: #5b5a5a">参考鞋图</span>
          <el-button type="primary" size="mini" style="position: absolute; transform: translate(0, 50px)" @click="openFileInput(2)" round>{{ btnText2 }}</el-button>
        </el-col>
      </el-row>

      <el-row :gutter="1">
        <el-col :span="4" style="display: flex; align-items: center; justify-content: center">
          <span style="font-size: 13px; margin-left: 10px; margin-top: 5px; color: #5b5a5a">融合：</span>
        </el-col>
        <el-col :span="7">
          <div class="mySlider">
            <el-slider v-model="rangeability" :min="1" :max="10" :show-tooltip="tooltipStatus" @input="tooltipStatus = true" @change="tooltipStatus = false"></el-slider>
          </div>
        </el-col>
        <el-col :span="4" style="margin-left: 10px; display: flex; align-items: center; justify-content: center">
          <span style="font-size: 13px; margin-left: 10px; margin-top: 5px; color: #5b5a5a">融合：</span>
        </el-col>
        <el-col :span="7">
          <div class="mySlider">
            <el-slider v-model="rangeability2" :min="1" :max="10" :show-tooltip="tooltipStatus" @input="tooltipStatus = true" @change="tooltipStatus = false"></el-slider>
          </div>
        </el-col>
      </el-row>

      <div class="c-body">
        <el-row style="height: 360px; display: flex; align-items: center; justify-content: center; background-color: #4a5569">
          <el-col :span="24">
            <el-image :src="currentPicUrl" :preview-src-list="pic_results" fit="contain" v-if="currentPicUrl"></el-image>
          </el-col>
        </el-row>
      </div>
      <el-row style="display: block; height: 30px; margin-bottom: 5px">
        <el-col :span="24">
          <el-button-group style="position: relative; left: 50%; transform: translate(-50%, 0)">
            <el-button type="primary" icon="el-icon-arrow-left" size="mini" @click="prevPic" :disabled="prevBtnDisabled">上一张</el-button>
            <el-button type="primary" size="mini" @click="nextPic" :disabled="nextBtnDisabled">下一张<i class="el-icon-arrow-right el-icon--right"></i></el-button>
          </el-button-group>
        </el-col>
      </el-row>
      <el-row :gutter="1" style="height: 50px; width: 100%"> </el-row>
    </el-main>
    <el-footer style="height: 50px; position: fixed; bottom: 0; left: 0; right: 0; z-index: 2000; background-color: #171631; border-top: 0.1mm solid #22374d">
      <el-button type="primary" style="position: relative; left: 70%; top: 50%; transform: translate(0, -50%)" @click="submit" round> 生 成 </el-button>
    </el-footer>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      loadingProgress: 60,
      loadingInterval: null,
      loading: null,
      tooltipStatus: true,
      rangeability: 5,
      rangeability2: 5,
      btnText: '上传',
      btnText2: '上传',
      thumbnail: '',
      thumbnail2: '',
      preview_thumbnail: [],
      preview_thumbnail2: [],
      pictureId: '',
      pictureId2: '',
      uploadPic1Info: {},
      uploadPic2Info: {},
      currentTaskId: null,
      intervalId: null,
      pic_results: [],
      current_pic_index: 0
    };
  },
  methods: {
    goBack() {
      this.$router.push('/function');
    },
    openFileInput(id) {
      if (id === 1) {
        this.$refs.fileInput.click();
      } else if (id === 2) {
        this.$refs.fileInput2.click();
      }
    },
    checkFileSize(file, id) {
      console.log('文件：', file);
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.warning('图片大小超限，文件不能超过 5M');
        return false;
      }

      if (id === 1) {
        this.uploadPic1Info.name = file.name;
        this.uploadPic1Info.size = file.size;
        this.uploadPic1Info.type = file.type;
        this.uploadPic1Info.lastModified = file.lastModified;
      }

      if (id === 2) {
        this.uploadPic2Info.name = file.name;
        this.uploadPic2Info.size = file.size;
        this.uploadPic2Info.type = file.type;
        this.uploadPic2Info.lastModified = file.lastModified;
      }

      const keys1 = Object.keys(this.uploadPic1Info);
      const keys2 = Object.keys(this.uploadPic2Info);

      if (keys1.length === keys2.length) {
        if (keys1.every((key) => this.uploadPic1Info[key] === this.uploadPic2Info[key])) {
          this.$message.warning('不可上传两张完全一样的图片！');
          return false;
        }
      }

      return true;
    },
    handleFileChange(id, event) {
      const file = event.target.files[0];
      if (!file) {
        return;
      }

      if (!file.type.includes('image')) {
        this.$message.warning('只能上传图片！');
        return;
      }

      if (!this.checkFileSize(file, id)) {
        return;
      }
      const formData = new FormData();
      formData.append('file', file);

      this.$http
        .post('file/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
          if (response.data.code !== '0') {
            this.$message.error('上传失败！');
            return;
          }

          console.log('上传成功', response.data);
          const tempPictureId = response.data.data;
          const picUrl = this.convertFileIdToUrl(tempPictureId);

          const reader = new FileReader();
          reader.onload = (event) => {
            if (id === 1) {
              this.thumbnail = event.target.result;
              this.pictureId = tempPictureId;
              this.preview_thumbnail = [];
              this.preview_thumbnail.push(picUrl);
              this.btnText = '重传';
            } else if (id === 2) {
              this.thumbnail2 = event.target.result;
              this.pictureId2 = tempPictureId;
              this.preview_thumbnail2 = [];
              this.preview_thumbnail2.push(picUrl);
              this.btnText2 = '重传';
            }
          };
          reader.readAsDataURL(file);

          // 定时任务关闭
          if (this.intervalId) {
            clearInterval(this.intervalId);
          }
          // 清除pic_results
          this.pic_results = [];
          this.current_pic_index = 0;
        })
        .catch((error) => {
          console.log(error.toString());
          this.$message.error('服务异常，请稍后再试！');
        });
    },

    submit() {
      if (!this.pictureId || !this.pictureId2) {
        this.$message.warning('请先上传两张图片！');
        return;
      }

      // 定时任务关闭
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }

      // 清除pic_results
      this.pic_results = [];
      this.current_pic_index = 0;

      const params = {};
      params.fileId = this.pictureId;
      params.fileId2 = this.pictureId2;
      params.rangeability = this.rangeability;
      params.rangeability2 = this.rangeability2;

      console.log('params: ' + JSON.stringify(params));

      this.$http
        .post('fusion-params/submit', params, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then((response) => {
          const { code, data, message } = response.data;
          if (code !== '0') {
            this.$message.error(message);
            return;
          }
          this.currentTaskId = data;
          this.$message.success('提交成功！');

          this.loadingProgress = 60;
          this.loading = this.$loading({
            lock: true,
            text: '图片生成中... ' + this.loadingProgress + '秒',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });

          this.loadingInterval = setInterval(() => {
            this.loadingProgress -= 1;
            this.loading.setText('图片生成中... ' + this.loadingProgress + '秒');
            if (this.loadingProgress === 0) {
              this.closeLoading();
            }
          }, 1000);

          // 启动定时拉取
          this.startFetchResult();
        })
        .catch((error) => {
          console.log(error.toString());
          this.closeLoading();
          this.$message.error('服务异常，请稍后再试！');
        });
    },
    closeLoading() {
      this.loading.close();
      if (this.loadingInterval) {
        clearInterval(this.loadingInterval);
      }
    },
    convertFileIdToUrl(fileId) {
      return this.$http.defaults.baseURL + '/file/download/' + fileId;
    },

    startFetchResult() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }

      this.intervalId = setInterval(() => {
        const params = { taskId: this.currentTaskId };
        this.$http
          .get('fetch-result', { params })
          .then((response) => {
            if (response.data.code !== '0') {
              console.log('定时拉取结果失败：' + response.data.message);
              return;
            }

            console.log('定时拉取结果成功', JSON.stringify(response.data));
            if (response.data.data.fileIds.length > 0) {
              this.closeLoading();
              this.pic_results = response.data.data.fileIds.map((fileId) => {
                return this.convertFileIdToUrl(fileId);
              });

              if (this.pic_results.length >= 4) {
                clearInterval(this.intervalId);
              }
            }
          })
          .catch((error) => {
            console.log(error.toString());
            this.$message.error('服务异常，请稍后再试！');
          });
      }, 2000);
    },
    prevPic() {
      // 切换到前一张图片
      if (this.current_pic_index > 0) {
        this.current_pic_index--;
      }
    },
    nextPic() {
      // 切换到下一张图片
      if (this.current_pic_index < this.pic_results.length - 1) {
        this.current_pic_index++;
      }
    }
  },
  computed: {
    currentPicUrl() {
      if (this.pic_results.length > 0) {
        return this.pic_results[this.current_pic_index];
      }
      return null;
      // return 'http://cybershoes.art/api/file/download/955844E0FDD17DAF6ECC29180D99024B.jpg';
    },
    prevBtnDisabled() {
      if (this.pic_results.length < 2) {
        return true;
      }
      return this.current_pic_index === 0;
    },
    nextBtnDisabled() {
      if (this.pic_results.length < 2) {
        return true;
      }
      return this.current_pic_index === this.pic_results.length - 1;
    }
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }

    if (this.loadingInterval) {
      clearInterval(this.loadingInterval);
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  padding: 0;
  background-color: #171631;

  overflow: hidden;
  height: 100vh;
  .el-main {
    padding: 3px;
  }
}

.c-header {
  position: fixed;
  top: 0;
  width: 100%;
  //height: 50px;
  padding-left: 20px;
  z-index: 2000;
  //border-bottom: 0.1mm solid #22374d;
  background-color: #171631;
  color: aliceblue;
}

.c-header {
  :deep(.el-page-header__content) {
    color: aliceblue;
  }
  :deep(.el-page-header__title) {
    font-size: 16px;
    font-weight: 500;
  }
}

.c-body {
  background-color: #efefef;
  overflow: hidden;
  border-radius: 20px;
  padding: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}

.el-row {
  display: flex;
  width: 100%;
  margin: 0;
}

.myInput {
  :deep(.el-input__inner) {
    background-color: var(--inputColor); //使用css变量 注意变量前需要加 --
  }
}

.mySlider {
  :deep(.el-slider__bar) {
    height: 2px;
  }

  :deep(.el-slider__runway) {
    height: 2px;
  }

  :deep(.el-slider__button) {
    height: 10px;
    width: 10px;
  }

  :deep(.el-slider__button-wrapper) {
    top: -17px;
  }

  :deep(.el-slider__input) {
    width: 80px;
  }
}
</style>
